/* PASSENGER INFO CARD */
.displayFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardStyle {
  border-radius: 8px !important;
  padding: 0.75rem !important;
  background-color: #fff !important;
}
.passengerIndex {
  color: #1a1a1a !important;
  font-size: 0.75rem !important;
  font-weight: bold !important;
}
.passengerCardPrimaryInfo {
  color: #1a1a1a !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  white-space: nowrap;
}
.passengerCardSecondaryInfo {
  color: #ada6ae !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}

/* SEAT LAYOUT */
/* changes color of seat with status => available noAvailable and occupied */
.seatIndicators {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 6px;
}
/* indicates on page we are from at bottom of seat layout */
.indicator {
  font-size: 12px !important;
}

.seatBox {
  border-radius: 4px;
  width: 32px;
  height: 32px;
  text-align: center;
  margin-right: 16px !important;
  padding: 5px;
}

/* SUBMIT SEAT CHANGE BUTTON */
.submit {
  height: 48px !important;
  background: #1a1a1a !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  box-shadow: 0px 4px 8px rgba(90, 90, 90, 0.48);
}
