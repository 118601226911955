.InputData {
    background: #FFF !important;
}

.inputSectionLabels {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #1A1A1A !important;
}
.inputSecondarySectionLabels {
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #1A1A1A !important;
}