
/* ~~~~~~~~~~~~~ALL THE CARDS ON PAGE~~~~~~~~~~~~~ */
  .paperCards {
    border-radius: 8px !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
    padding: 16px;
  }
  .dropShadow {
    border-radius: 8px !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
  }